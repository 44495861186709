import { Line, Chart } from 'react-chartjs-2';
import { emptyChart } from '@src/modules/performance/shared/chartPlugins';
import { ChartColours as COLOURS } from './ChartColours';

Chart.register([emptyChart]);

function StackedLineChart({
  grouping, labels, datasets, colours, unitprefix, unitpostfix, showLegend,
}) {
  const height = 250;
  const width = 250;

  const data = {
    labels: grouping, // grouping on x axis
    datasets: [],
  };

  const maxValues = [];
  const minValues = [];

  // populate the datasets
  datasets.forEach((ds, index) => {
    const config = {
      label: labels[index],
      data: ds,
      borderColor: COLOURS[colours[index]], // line colour
      backgroundColor: COLOURS[colours[index]], // also sets the colour of the legend
      pointRadius: 0, // hide the dots
      borderWidth: 0, // thickness of the line
      fill: 'stack', // fill all the areas under the lines
      // this 'stack' option gives nicer colours.
      // if we use 'true', the transparent colours are piled on top of each other
      // and it all turns into a muddy coloured mess.
    };
    maxValues.push(Math.max(...ds));
    minValues.push(Math.min(...ds));
    data.datasets.push(config);
  });

  let decimalPrecision = 0;

  if (Math.max(...maxValues) <= 2) {
    decimalPrecision = 1;
  }

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${unitprefix}${tooltipItem.raw.toLocaleString(
            'en-NZ',
            { minimumFractionDigits: decimalPrecision || 0, maximumFractionDigits: decimalPrecision || 0 },
          )}${unitpostfix}`,
        },
        // hide tooltips if there is no label
        filter: (tooltipItem) => tooltipItem.datasetIndex < labels.length,
      },
      legend: {
        // hide the legend
        display: showLegend,
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
        },
        position: 'top',
      },
      datalabels: {
        display: false,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    spanGaps: true,
    scales: {
      y: {
        position: 'right', // y-axis on the right
        suggestedMin: 0,
        ticks: {
          beginAtZero: true,
          precision: decimalPrecision, // no decimals on y axis
          padding: 20, // spacing between ticks and y-values
          callback: (value) => `${unitprefix}${value.toLocaleString(
            'en-NZ',
            { minimumFractionDigits: decimalPrecision || 0, maximumFractionDigits: decimalPrecision || 0 },
          )}${unitpostfix}` // add units to y axis values
          ,
        },
        grid: {
          drawBorder: false, // hide the y-axis bar
        },
        // this ensures the values properly stack and subtract
        stacked: true,
      },
      x: {
        grid: {
          display: false, // hide the vertical grid lines
        },
      },
    },
  };

  // y axis clamping
  // ensure we don't go below 0
  const min = Math.min(...minValues);
  if (min > 0) {
    // do nothing
  } else {
    // ensure we clamp to zero
    options.scales.y.min = 0;
  }

  return (
    <Line data={data} options={options} height={height} width={width} />
  );
}

export default StackedLineChart;
