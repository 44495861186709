import {
  Button,
  HStack,
  Modal,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import FilterConfirmationModalContent from '@tce/components/pages/traffic-count-estimates/components/wizard/step3/FilterConfirmationModalContent';
import { useJob } from '@tce/context/jobs';
import { useAssignTrafficGroupMutation } from '@tce/context/roadsections';
import { useTceTrafficGroups } from '@tce/context/trafficgroups';
import {
  numTotalRowsFilteredState,
  numTotalRowsSelectedState, tceWizardFilterSelector,
} from '@tce/state/tce';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

function TrafficGroupAssignControls({
  gridRef,
  selectAll,
  setSelectAll,
  name,
}) {
  const queryClient = useQueryClient();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const numberOfSelectedRows = useRecoilValue(numTotalRowsSelectedState);
  const numberOfFilteredRows = useRecoilValue(numTotalRowsFilteredState);
  const roadFilter = useRecoilValue(tceWizardFilterSelector(name));
  const FilterConfirmationModalState = useDisclosure();
  const { job } = useJob();

  const { mutate: assignTrafficGroup } = useAssignTrafficGroupMutation({
    onSuccess: () => {
      // Invalidate grid data
      queryClient.invalidateQueries('tce-roadsections');
      gridRef?.current?.api?.deselectAll();
      setSelectAll(false);
      FilterConfirmationModalState.onClose();
    },
  });

  const { isLoading: isLoadingTrafficGroups, trafficgroups } = useTceTrafficGroups(
    job?.lockYear,
  );

  const onSelectGroup = (e) => {
    const group = trafficgroups.find((g) => g.id === e.target.value);
    setSelectedGroup(group);
  };

  const filterInEffect = roadFilter.roadName || roadFilter.trafficGroupId || roadFilter.categoryId
    || roadFilter.hierarchy || roadFilter.onlyMissingTrafficGroups || roadFilter.urbanRural;

  const assignToFilteredRoadSections = () => {
    const {
      roadName, trafficGroupId, categoryId, hierarchy, urbanRural, onlyMissingTrafficGroups,
    } = roadFilter;
    const data = {
      trafficGroupIdToAssign: selectedGroup.id,
      roadName,
      trafficGroupId,
      categoryId,
      hierarchy,
      urbanRural,
      onlyMissingTrafficGroups,
    };
    assignTrafficGroup(data);
  };

  const assignToSelectedRoadSections = () => {
    const data = {
      trafficGroupIdToAssign: selectedGroup.id,
      roadSections: [],
    };
    gridRef.current.api.getSelectedNodes().forEach((node) => {
      data.roadSections.push(node.data.id);
      const section = node.data;
      section.trafficGroupId = selectedGroup.id;
    });
    assignTrafficGroup(data);
    FilterConfirmationModalState.onClose();
  };

  const onUpdateGroupBatch = () => {
    // Ask for confirmation only when the filter is being used
    // and the check all checbox is clicked
    // and the number of num of filtered roads > selected rows
    if (filterInEffect && selectAll && numberOfFilteredRows > numberOfSelectedRows) {
      FilterConfirmationModalState.onOpen();
    } else {
      assignToSelectedRoadSections();
    }
  };

  if (isLoadingTrafficGroups) return <Spinner />;

  return (
    <HStack
      width="fit-content"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="md"
      p="2"
      mb="3"
    >
      <HStack spacing={2}>
        <Text
          alignSelf="center"
          fontSize="md"
        >
          Change traffic group for all selected
          {' '}
          <b>
            {numberOfSelectedRows}
            {' '}
            Carriageways
          </b>
          {' '}
          to
        </Text>
        <Select
          flex={2}
          ml={0}
          placeholder="Select Group"
          bgGradient="linear(to-b, gray.50, gray.100)"
          onChange={onSelectGroup}
          size="sm"
        >
          {trafficgroups && trafficgroups.map((group, index) => (
            <option key={index} value={group.id}>{group.code}</option>
          ))}
        </Select>
      </HStack>
      <HStack>
        <Button
          disabled={numberOfSelectedRows === 0 || !selectedGroup}
          onClick={onUpdateGroupBatch}
          size="sm"
        >
          Update
        </Button>
      </HStack>
      <Modal
        size="md"
        isOpen={FilterConfirmationModalState.isOpen}
        onClose={FilterConfirmationModalState.onClose}
        closeOnOverlayClick={false}
        isCentered
        preserveScrollBarGap
      >
        <ModalOverlay />
        <FilterConfirmationModalContent
          trafficGroup={selectedGroup}
          onAssignToFilter={assignToFilteredRoadSections}
          onAssignToSelected={assignToSelectedRoadSections}
        />
      </Modal>
    </HStack>
  );
}

export default TrafficGroupAssignControls;
