import {
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import { usePortalNavContext } from '@transport-insights/uikit';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function Resilience() {
  const { isLoading } = usePortalNavContext();
  const { data: report, isLoading: isLoadingReport } = useReports('resilience');
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'resilience');
  const localRoadsData = report?.data?.localRoads;
  const sortedLocalRoadsData = localRoadsData?.slice().sort((a, b) => a.roadCouncil.localeCompare(b.roadCouncil));
  const localRoadsRegionTotal = sortedLocalRoadsData?.reduce((acc, obj) => acc + obj.closureCount, 0);
  const stateHighwaysData = report?.data?.stateHighways;
  const sortedStateHighwaysData = stateHighwaysData?.slice().sort((a, b) => a.stateHighway.localeCompare(b.stateHighway, undefined, { numeric: true }));

  const allCauses = [...new Set(localRoadsData?.map((x) => x.cause))];
  const allCouncils = [...new Set(localRoadsData?.map((x) => x.roadCouncil))];
  const groupedByCause = allCauses.map((cause) => {
    const matchingValues = localRoadsData.filter((item) => item.cause === cause);
    const interpolatedData = allCouncils.map((council) => {
      const matchingValue = matchingValues.find((item) => item.roadCouncil === council);
      if (matchingValue) {
        return { x: council, y: matchingValue.closureCount, councilColour: matchingValue.councilColour };
      }
      // PMRT-5240 Colour doesn't matter as the chart wont show it
      // We just need values for each council so that the chart
      // tooltips work properly
      return { x: council, y: 0, councilColour: '#000000' };
    });
    return {
      name: cause,
      data: interpolatedData,
    };
  });

  const years = [...new Set(stateHighwaysData?.map((x) => x.year))];
  const stateHighways = Object.entries(groupBy(sortedStateHighwaysData, 'stateHighway'))
    .map(([k, v]) => {
      const interpolatedData = years.map((year) => {
        const matchingValue = v.find((item) => item.year === year);
        if (matchingValue) {
          return { x: year, y: matchingValue.closureCount };
        }
        return { x: year, y: 0 };
      })
        .sort((a, b) => a.x.localeCompare(b.x));
      return {
        name: k,
        data: interpolatedData,
      };
    });

  if (isLoading || isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="resilience" reportData={localRoadsData} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="resilience" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="resilience" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text dangerouslySetInnerHTML={{ __html: notification }} />
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Disruptions on local roads in
            {' '}
            {regionName}
            {' '}
            {report?.lockYear}
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                  },
                  colors: groupedByCause.flatMap((x) => x.data.map((y) => y.councilColour)),
                  xaxis: {
                    labels: {
                      style: {
                        fontWeight: 'bold',
                      },
                    },
                    tickPlacement: 'between',
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'No. of Events',
                      },
                    },
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                    labels: {
                      useSeriesColors: false,
                    },
                  },
                  stroke: {
                    show: false,
                    colors: ['transparent'],
                    width: 0,
                  },
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    shared: true,
                    marker: {
                      show: false,
                    },
                    inverseOrder: true,
                    y: {
                      formatter: (v) => (v === 0 ? null : v),
                    },
                  },
                  plotOptions: {
                    bar: {
                      distributed: true,
                      horizontal: false,
                    },
                  },
                }
              }
              series={groupedByCause}
              type="bar"
              width="100%"
              height="400"
            />
            <Text textAlign="center">
              Total no. of events for
              {' '}
              {regionName}
              :
              {' '}
              {localRoadsRegionTotal}
            </Text>
          </ChartContainer>
          <Heading as="h2" mt={6} mb={4} fontWeight="normal" fontSize="2xl">
            Disruptions on state highways in
            {' '}
            {regionName}
          </Heading>
          <Text mb={4}>
            Reporting year 1 July to 30 June
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#3366cc', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411'],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                  },
                  xaxis:
                  {
                    categories: years,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'No. of Events',
                      },
                      min: 0,
                      labels: {
                        formatter(val) {
                          return Math.round(val);
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                  },
                  markers: {
                    size: 6,
                  },
                  stroke: {
                    width: 3,
                  },
                }
              }
              series={stateHighways}
              width="100%"
              height="500"
              type="line"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
