import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector, selectedLockYearState } from '@transport-insights/uikit';
import { useQuery } from 'react-query';
import {
  calculateAchievementsSlope,
  calculateActivityManagement,
  calculateCoInvestorAssuranceInvestment,
  calculateSafetySlope,
  calculateAverageYearRange,
} from '@src/modules/performance/shared/kpi_calcs';
import { councilIds } from '@src/modules/performance/shared/councilnames';
import { mean } from 'lodash';
import usePerformanceMapApi from '../api/usePerformanceMapApi';

export const MAP_PAGE_LOCK_YEAR = 'latest';

export function useSafetyKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  const queryInfo = useQuery(
    ['safety-kpi', rca?.id],
    async () => api.getSafetyKpiData(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );

  let processedData = {
    dsi: 'No data',
    totalCrashes: 0,
    colour: 'GREY',
    marker: 'none',
  };
  if (queryInfo?.data) {
    const years = [];
    const dsi = [];

    queryInfo.data.forEach((val) => {
      years.push(parseInt(val.Year, 10));
      dsi.push(parseInt(val.dsi, 10));
    });
    const totalCrashes = mean(queryInfo.data.map((item) => item.totalCrashes));
    processedData = { ...calculateSafetySlope(years, dsi), totalCrashes: totalCrashes > 5 ? Math.round(totalCrashes) : Math.round(totalCrashes * 10) / 10 };
  }
  return {
    ...queryInfo,
    data: { ...processedData, lockYear: queryInfo.data?.[0]?.LockYear, averageYearRange: calculateAverageYearRange(queryInfo.data?.[0]?.LockYear, 5) },
  };
}

export function useAchievementsKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  const queryInfo = useQuery(
    ['achievement-kpi', rca?.id],
    async () => api.getAchievementKpiData(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );

  let achievement = {
    value: 'No data',
    colour: 'GREY',
    marker: 'none',
  };
  if (queryInfo?.data) {
    const years = [];
    const crashes = [];

    queryInfo.data.forEach((val) => {
      years.push(parseInt(val.Year, 10));
      crashes.push(parseFloat(val.Value));
    });
    achievement = calculateAchievementsSlope(years, crashes);
    if (achievement.value !== null) {
      achievement.value = achievement.value.toFixed(1);
    }
  }

  return {
    ...queryInfo,
    data: { ...achievement, lockYear: queryInfo.data?.[0]?.LockYear },
  };
}

export function useDeliveryPerformanceKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  return useQuery(
    ['delivery-performance-kpi', rca?.id],
    async () => api.getDeliveryPerformanceKpiData(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useActivityManagementKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  const queryInfo = useQuery(
    ['activity-management-kpi', rca?.id],
    async () => api.getActivityManagementKPI(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
  let value = 0;
  if (queryInfo?.data) {
    const result = queryInfo.data;
    if (result[0]) {
      value = calculateActivityManagement(
        parseFloat(result[0].Average_score_Pillars_of_Success),
        parseFloat(result[0].Average_score_TIO),
        parseFloat(result[0].Smart_buyer_selfassessment),
        parseFloat(result[0].Score),
      );
    }
  }

  return {
    ...queryInfo,
    data: value,
  };
}

export function useCoInvestorAssuranceKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  const queryInfo = useQuery(
    ['co-investor-assurance-kpi', rca?.id],
    async () => api.getCoInvestorAssuranceKPI(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
  let kpiData = {
    score: 0,
    isTechnical: true,
    auditDate: null,
  };
  if (queryInfo?.data && queryInfo?.data?.length > 0) {
    const result = queryInfo.data;
    const { score, isTechnical, auditDate } = calculateCoInvestorAssuranceInvestment(
      result[0].Combined_Audit_Score,
      parseFloat(result[0].Overall_Combined_Score),
      parseFloat(result[0].Overall_Technical_Score),
      result[0].Technicial_Audit_Report_date,
      result[0].Combined_Audit_date,
    );
    kpiData = {
      score,
      isTechnical,
      auditDate,
    };
  }

  return {
    ...queryInfo,
    data: kpiData,
  };
}

export function useServicePerformanceMapKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  return useQuery(
    ['service-performance-map-kpi', rca?.id],
    async () => api.getServicePerformanceMapKPI(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

export function useDataQualityKPI() {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  return useQuery(
    ['data-quality-kpi', rca?.id],
    async () => api.getDataQualityKPI(rca?.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
}

/*
  Returns the data for all RCAs displayed on the map
*/

export function useMapData(type, filter = null) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const api = usePerformanceMapApi();
  const typeUrl = type?.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());
  const queryInfo = useQuery(
    ['map-data', rca?.id, typeUrl],
    async () => api.getMapData(typeUrl, rca.id, MAP_PAGE_LOCK_YEAR),
    {
      enabled: rca?.id !== undefined,
    },
  );
  const results = {};
  if (queryInfo?.data) {
    const result = queryInfo.data;
    // process returned data based on type
    switch (type) {
      case 'safety':
        councilIds.forEach((id) => {
          const values = result.filter((r) => r.id === id);
          const years = [];
          const dsi = [];

          values.forEach((val) => {
            years.push(parseInt(val.Year, 10));
            dsi.push(parseInt(val.dsi, 10));
          });

          const totalCrashes = mean(values.map((item) => item.totalCrashes));
          const kpi = { ...calculateSafetySlope(years, dsi), totalCrashes: totalCrashes > 5 ? Math.round(totalCrashes) : Math.round(totalCrashes * 10) / 10 };
          results[id] = kpi;
        });
        break;
      case 'amenityCondition':
        councilIds.forEach((id) => {
          const values = result.filter((r) => r.id === id);
          const years = [];
          const crashes = [];

          values.forEach((val) => {
            years.push(parseInt(val.Year, 10));
            crashes.push(parseFloat(val.Value));
          });

          const kpi = calculateAchievementsSlope(years, crashes);
          results[id] = kpi;
        });
        break;
      case 'activity':
        councilIds.forEach((id) => {
          const value = result.filter((r) => r.id === id);
          if (value && value[0]) {
            const data = value[0];
            let filterValue = 0;
            let subText = '';
            switch (filter) {
              case 'Overall':
                // Need to calculate the weighted average across all four results (Marcels code)
                filterValue = calculateActivityManagement(
                  parseFloat(data.Average_score_Pillars_of_Success),
                  parseFloat(data.Average_score_TIO),
                  parseFloat(data.Smart_buyer_selfassessment),
                  parseFloat(data.Score),
                );
                break;
              case 'Smart_buyer_selfassessment':
                filterValue = data[filter];
                subText = data.SmartBuyerAssessmentDate;
                break;
              default:
                filterValue = data[filter];
                break;
            }

            results[id] = {
              value: filterValue,
              subText,
            };
          }
        });
        break;
      case 'coassurance':
        councilIds.forEach((id) => {
          const value = result.filter((r) => r.id === id);
          if (value && value[0]) {
            const data = value[0];
            results[id] = calculateCoInvestorAssuranceInvestment(
              data.Combined_Audit_Score,
              parseFloat(data.Overall_Combined_Score),
              parseFloat(data.Overall_Technical_Score),
              data.Technicial_Audit_Report_date,
              data.Combined_Audit_date,
            );
          }
        });
        break;
      case 'servicePerformance':
        councilIds.forEach((id) => {
          const council = result.find((r) => r.id === id && r.Type_name === filter);
          results[id] = {
            value: council ? council.Result.toLowerCase() : null,
            year: council ? council.LockYear : null,
          };
        });
        break;
      case 'dataQuality':
        councilIds.forEach((id) => {
          const council = result.find((r) => r.id === id && r.GroupType === (filter || null));
          if (council) {
            results[id] = {
              id: council.id,
              value: council.Value,
              groupType: council.GroupType,
            };
          }
        });
        break;
      default:
        break;
    }
  }

  return {
    ...queryInfo,
    data: results,
  };
}

export function useNetworkCharacteristics(providedLockYear) {
  const rca = useRecoilValue(currentDatabaseSelector);
  const selectedLockYear = useRecoilValue(selectedLockYearState);
  const lockYear = providedLockYear || selectedLockYear;

  const api = usePerformanceMapApi();
  return useQuery(
    ['network-characteristics', rca?.id, lockYear],
    async () => api.getNetworkCharacteristics(rca.id, lockYear),
    {
      enabled: rca?.id !== undefined && lockYear !== null,
    },
  );
}
