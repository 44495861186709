import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  useDisclosure,
  VStack,
  HStack,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tbody,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Switch,
  Heading,
  TableContainer,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { useFetchTrafficGroupAudit } from '../../context/jobs';

const CountSiteName = React.memo(({ site }) => (
  <Text
    as="span"
    flex="1"
    textAlign="left"
    fontSize="xs"
    fontWeight="bold"
    color="inherit"
    align="center"
  >
    {site.roadName}
    <Text
      as="span"
      fontWeight="normal"
    >
      {' ('}
      {site.linearLocation}
      {'m - '}
      {site.cwayEnd}
      m)
    </Text>
  </Text>
));
CountSiteName.displayName = 'CountSiteName';

const CountSite = React.memo(({ siteKey, site }) => (
  <AccordionItem key={siteKey}>
    <AccordionButton
      pl={2}
      py={2}
      _expanded={{
        bg: 'gray.50', color: 'brand.orange.500', borderBottom: '1px solid', borderColor: 'gray.100',
      }}
    >
      <AccordionIcon mr={2} />
      <CountSiteName site={site} />
      <HStack>
        <HStack align="center" spacing={8}>
          {site.calculatedAnnualGrowthPct && (
          <Text
            as="span"
            fontSize="sm"
            color="gray.700"
            fontWeight="bold"
          >
              {site.calculatedAnnualGrowthPct.toFixed(1)}
              {'% '}
            <Text
              as="span"
              fontSize="xs"
              color="gray.500"
            >
              Growth Rate
            </Text>
          </Text>
          )}
          <Text
            as="span"
            fontSize="sm"
            fontWeight="bold"
            color="gray.700"
          >
            {site.estimatedADT?.toLocaleString('en-NZ')}
            {' '}
            <Text
              as="span"
              fontSize="xs"
              color="gray.500"
            >
              ADT
            </Text>
          </Text>
          <Text
            as="span"
            fontSize="sm"
            fontWeight="bold"
            color="gray.700"
          >
            {site.heavyPercentage?.toFixed(1)}
            {'% '}
            <Text
              as="span"
              fontSize="xs"
              color="gray.500"
            >
              Heavy
            </Text>
          </Text>
        </HStack>
      </HStack>
    </AccordionButton>
    <AccordionPanel p={0}>
      <HStack p={2} px={4} justify="flex-end" spacing={5}>
        <Text fontSize="xs">
          <Text as="span" fontWeight="bold">
            {site.cwayNo}
          </Text>
          {' '}
          Cway #
        </Text>
        <Text fontSize="xs">
          <Text as="span" fontWeight="bold">
            {site.roadId}
          </Text>
          {' '}
          Road Id
        </Text>
        <Text fontSize="xs">
          Derived from
          <Text as="span" fontWeight="bold">
            {' '}
            {site.derivedFrom}
          </Text>
        </Text>
        {site.previousADT && (
        <Text fontSize="xs">
          <Text as="span" fontWeight="bold">
            {site.previousADT?.toLocaleString('en-NZ')}
          </Text>
          {' '}
          Previous ADT
        </Text>
        )}
      </HStack>
      <Accordion
        allowMultiple
        borderColor="gray.100"
        key={`accordion-${siteKey}`}
      >
        {site.counts?.map((count, countIdx) => (
          <AccordionItem key={`count-${countIdx}`}>
            <AccordionButton py={2} pl={8}>
              <AccordionIcon mr={2} />
              <Text
                as="span"
                fontSize="sm"
                textAlign="left"
                fontWeight="bold"
              >
                {new Date(count.countDate).toLocaleDateString('en-NZ')}
              </Text>
              <HStack spacing={8} ml={8}>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  color="gray.700"
                >
                  {count.count?.toLocaleString('en-NZ')}
                  {' '}
                  <Text
                    fontSize="xs"
                    color="gray.500"
                    as="span"
                  >
                    ADT
                  </Text>
                </Text>
                {count.calculatedAnnualGrowthPct
                  && (
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="gray.700"
                    >
                      {count.calculatedAnnualGrowthPct.toFixed(1)}
                      {'% '}
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        as="span"
                      >
                        Rate of change
                      </Text>
                    </Text>
                  )}
              </HStack>
              <Switch
                id="enabled"
                title={count.isExcluded ? 'Excluded from count' : 'Included in count'}
                size="sm"
                isChecked={count.isExcluded}
                colorScheme="red"
                sx={{ 'span.chakra-switch__track:not([data-checked])': { backgroundColor: 'green.500' } }}
                marginLeft="auto"
              />
            </AccordionButton>
            <AccordionPanel pl={8} pb={2} borderTop="1px solid" borderColor="gray.100">
              <TableContainer
                border="1px solid"
                borderColor="gray.100"
                borderRadius="md"
                overflow="hidden"
              >
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Heavy</Th>
                      <Th>Car</Th>
                      <Th>LCV</Th>
                      <Th>MCV</Th>
                      <Th>HCVI</Th>
                      <Th>HCVII</Th>
                      <Th>Buses</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td borderBottom="none">
                        {count.heavyPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.carPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.lcvPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.mcvPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.hcviPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.hcviiPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                      <Td borderBottom="none">
                        {count.busesPercentage?.toFixed(1) ?? 0}
                        %
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </AccordionPanel>
  </AccordionItem>
));
CountSite.displayName = 'CountSite';

function TrafficGroupAudit({ lockYear, importId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useFetchTrafficGroupAudit();
  const [activeGroup, setActiveGroup] = useState(null);

  // State to keep track of expanded indices in the accordion
  const [expandedIndices, setExpandedIndices] = useState([]);
  const expandAll = () => setExpandedIndices(activeGroup?.countSites.map((_, index) => index));
  const collapseAll = () => setExpandedIndices([]);

  const handleGroupClick = (group) => {
    setActiveGroup(group);
    setExpandedIndices([]);
  };

  // Create a list of traffic groups to be used for navigation
  const trafficGroupList = useMemo(
    () => data?.trafficGroups?.map((group) => (
      <VStack
        onClick={() => handleGroupClick(group)}
        align="flex-start"
        cursor="pointer"
        px={3}
        py={2}
        w="100%"
        borderRadius="base"
        bgColor={activeGroup?.id === group.id ? 'brand.orange.500' : 'gray.50'}
        color={activeGroup?.id === group.id ? 'whiteAlpha.900' : 'gray.700'}
        _hover={{
          bgColor: activeGroup?.id === group.id ? 'brand.orange.500' : 'gray.100',
        }}
        transition="all 0.2s"
        key={group.id}
        spacing={0}
      >
        <HStack width="100%" justify="space-between">
          <Text fontSize="sm" color="inherit" fontWeight="bold">
            {group.groupName}
          </Text>
          {group.calculatedGrowthRatePercent
            && (
            <Text fontSize="sm" color="inherit" fontWeight="normal">
              {group.calculatedGrowthRatePercent?.toFixed(1)}
              %
            </Text>
            )}
        </HStack>
      </VStack>
    )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.trafficGroups, activeGroup?.id],
  );

  // Set the first group as active when data is loaded
  useEffect(() => {
    if (data?.trafficGroups) {
      setActiveGroup(data.trafficGroups[0]);
    }
  }, [data]);

  return (
    <>
      <Button onClick={onOpen} size="sm" isDisabled={isLoading}>Traffic Group Audit</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Traffic Group Audit for
            {' '}
            {`${lockYear} #${importId}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {isLoading && <Flex justify="center" align="center" h="200px"><Spinner colorScheme="brand.orange" /></Flex>}
            {!isLoading && data.trafficGroups.length === 0 && (
            <Flex justify="center" align="center" h="200px">
              <Text textAlign="center">
                Auditing is not available for old completed runs.
                <br />
                Please calculate a new set of estimates to use Auditing.
              </Text>
            </Flex>
            )}
            {!isLoading && data?.trafficGroups?.length > 0 && (
            <Grid
              gridGap={4}
              templateColumns="1fr 3fr"
            >
              <GridItem>
                <VStack
                  align="flex-start"
                  spacing={2}
                >
                  {trafficGroupList}
                </VStack>
              </GridItem>
              <GridItem>
                <HStack
                  width="100%"
                  justify="space-between"
                  align="center"
                  mb={4}
                >
                  <VStack align="flex-start" spacing={1}>
                    <Heading as="h2" size="md" mb={0}>
                      {activeGroup?.groupName}
                    </Heading>
                    <Text color="gray.500" pb={0}>
                      {activeGroup?.description}
                    </Text>
                  </VStack>
                  <HStack spacing={2}>
                    {activeGroup?.calculatedGrowthRatePercent && (
                    <VStack
                      spacing={0}
                      border="1px solid"
                      borderRadius="base"
                      borderColor="gray.100"
                      py={1}
                      px={2}
                    >
                      <Text fontSize="xl" fontWeight="bold">
                        {activeGroup?.calculatedGrowthRatePercent?.toFixed(1)}
                        %
                      </Text>
                      <Text fontSize="xs" color="gray.500">Growth Rate</Text>
                    </VStack>
                    )}
                    <VStack
                      spacing={0}
                      border="1px solid"
                      borderRadius="base"
                      borderColor="gray.100"
                      py={1}
                      px={2}
                    >
                      <Text fontSize="xl" fontWeight="bold">
                        {activeGroup?.calculatedHeavyPercentage.toFixed(1)}
                        %
                      </Text>
                      <Text fontSize="xs" color="gray.500">Heavy</Text>
                    </VStack>
                  </HStack>
                </HStack>
                {expandedIndices.length > 1 ? (
                  <Button
                    size="xs"
                    colorScheme="gray"
                    variant="outline"
                    mb={4}
                    onClick={collapseAll}
                  >
                    Collapse all Roads
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    colorScheme="gray"
                    variant="outline"
                    mb={4}
                    onClick={expandAll}
                  >
                    Expand all Roads
                  </Button>
                )}
                <Accordion
                  allowMultiple
                  key={`group-${activeGroup?.id}`}
                  borderColor="gray.100"
                  index={expandedIndices}
                  onChange={(indices) => setExpandedIndices(indices)}
                >
                  {activeGroup?.countSites?.map((site, siteIdx) => (<CountSite key={`site-${siteIdx}`} siteKey={`site-${siteIdx}`} site={site} />))}
                </Accordion>
              </GridItem>
            </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TrafficGroupAudit;
